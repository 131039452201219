@import '../../../scss/theme-bootstrap';

.holiday-nodeblock-noderef {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $cr19-max-width-xlarge;
  width: 100%;
  .hero-full-width.content-block {
    clip-path: polygon(1% 2%, 99% 2%, 99% 98%, 1% 98%);
  }
}
